import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Select from 'react-select';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import variables from 'scss/1-settings/colors.scss';
import './SelectField.scss';

const reactSelectTheme = theme => ({
  ...theme,
  borderRadius: '0.2rem',
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const findValue = (options, field, isMulti) => {
  if (!field || !field.value) return null;

  if (!isMulti) {
    return (options || []).find(x => x.value === field.value);
  }

  return (options || []).filter(x => field.value.indexOf(x.value) > -1);
};

const renderSelect = ({ form, field, options, isMulti, ...props }) => (
  <Select
    {...props}
    classNamePrefix="rs"
    defaultValue={findValue(options, field, isMulti)}
    isMulti={isMulti}
    theme={reactSelectTheme}
    onChange={option => {
      if (isMulti) {
        form.setFieldValue(field.name, option.map(x => x.value));
      } else {
        form.setFieldValue(field.name, option.value);
      }
    }}
    options={options}
    value={findValue(options, field, isMulti)}
    openMenuOnFocus
  />
);

const SelectField = props => {
  const { validate, label, name } = props;

  return (
    <Field
      {...props}
      component={renderSelect}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
    />
  );
};
SelectField.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string,
};
SelectField.defaultProps = {
  validate: [],
  label: '',
  name: '',
};

export default SelectField;
