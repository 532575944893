import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import TextEditorBase from 'shared/components/text-editor/TextEditor';

const renderTextEditor = ({ field, form, onChange, ...props }) => (
  <TextEditorBase
    {...props}
    initialValue={field.value}
    onChange={value => {
      form.setFieldValue(field.name, value);
      if (onChange) {
        onChange(value);
      }
    }}
  />
);
renderTextEditor.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
};

const TextEditor = props => {
  const { validate, label, name, toolbarOptions } = props;

  return (
    <Field
      {...props}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
      render={p =>
        renderTextEditor({
          ...props,
          ...p,
          toolbarOptions,
        })
      }
    />
  );
};

TextEditor.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string,
  toolbarOptions: PropTypes.shape({}),
};

TextEditor.defaultProps = {
  validate: [],
  label: '',
  name: '',
  toolbarOptions: null,
};

export default TextEditor;
