import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import MaterialTable, { MTableHeader, MTableFilterRow } from 'material-table';

import { visibility } from 'shared/enhancers';

import { changeSort, changePage, changeRowsPerPage } from './actions';
import filterActions from './filterActions';
import TableRow from './TableRow';
import TableActions from './TableActions';
import TableBody from './TableBody';
import TableToolbar from './TableToolbar';
import ToolbarActions from './ToolbarActions';
import TablePagination from './TablePagination';
import './MaterialTable.scss';

const selectTableMetaData = (state, { name }) => state.dataTable[name] || {};

const enhance = compose(
  withProps(({ visible }) => ({
    visible: ((visible === undefined || visible === null) && true) || visible,
  })),
  visibility,
  connect(
    (state, props) => {
      const metaData = selectTableMetaData(state, props);

      return {
        metaData,
        formatExtraData: {
          ...props.formatExtraData,
        },
      };
    },
    (dispatch, props) => ({
      onOrderChange: (sortIndex, sortOrder) =>
        dispatch(
          changeSort({
            name: props.name,
            sortIndex,
            sortOrder,
          })
        ),
      onChangePage: page =>
        dispatch(
          changePage({
            name: props.name,
            page,
          })
        ),
      onChangeRowsPerPage: pageSize =>
        dispatch(
          changeRowsPerPage({
            name: props.name,
            pageSize,
          })
        ),
    })
  ),
  withState('selected', 'setSelected', []),
  withHandlers({
    isSelected: props => id => props.selected.indexOf(id) > -1,
  })
);

// TODO: materal-table currently makes changes to the data that makes it back to
// redux.  This stops that.  Temporary fix until I get time to fix correct
const prepareData = data =>
  data.map(x => ({
    ...x,
  }));

const DataTable = ({ options, actions, data, metaData, ...props }) => {
  const actionsFilter = filterActions(options);
  const finalOptions = {
    ...options,
    ...metaData,
    headerStyle: {
      fontFamily: 'Karla, Segoe UI, Roboto, Arial, sans-serif',
      backgroundColor: '#EFF3F7',
      color: '#25282A',
    },
    rowStyle: {
      fontFamily: 'Karla, Segoe UI, Roboto, Arial, sans-serif',
      backgroundColor: '#FFF',
    },
  };

  return (
    <React.Fragment>
      <MaterialTable
        {...props}
        options={finalOptions}
        actions={actions}
        data={prepareData(data)}
        components={{
          Container: p => <div {...p} />,
          Header: p => (
            <MTableHeader
              {...p}
              showActionsColumn={
                actions && actions.filter(actionsFilter).length > 0
              }
            />
          ),
          FilterRow: p => (
            <MTableFilterRow
              {...p}
              hasDetailPanel
              hasActions={actions.filter(actionsFilter).length > 0}
            />
          ),
          Row: p => <TableRow {...p} />,
          Actions: p => <TableActions {...p} />,
          Body: p => <TableBody {...p} currentPage={metaData.page || 0} />,
          Toolbar: p => (
            <TableToolbar
              {...p}
              options={finalOptions}
              actions={
                actions &&
                actions.filter(x => x.isFreeAction || x.isSelectedAction)
              }
            />
          ),
          ToolbarActions,
          Pagination: p => (
            <TablePagination
              metaData={metaData}
              {...p}
              options={finalOptions}
            />
          ),
        }}
      />
    </React.Fragment>
  );
};
DataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape(),
  actions: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.arrayOf(PropTypes.shape()),
};
DataTable.defaultProps = {
  title: '',
  options: {},
  actions: [],
  data: [],
};

export default enhance(DataTable);
