import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Tabs.scss';

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { border, className, content, justify } = this.props;
    const borderClass = {
      default: '',
      bottom: 'bordered-bottom',
      top: 'bordered-top',
    };

    return (
      <div
        className={classnames(
          'tabs',
          { 'nav-justified': justify },
          borderClass[border],
          className
        )}
      >
        <div className="tabs-wrap">
          <Nav tabs>
            {content.map((item, index) => (
              <NavItem key={`${item.tabName + index}.link`}>
                <NavLink
                  className={classnames({
                    active: activeTab === index,
                  })}
                  onClick={() => {
                    this.toggle(index);
                  }}
                  tag="button"
                >
                  {item.tabName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {content.map((item, index) => (
              <TabPane key={`${item.tabName + index}.content`} tabId={index}>
                {item.tabContent}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  border: PropTypes.oneOf(['default', 'top', 'bottom']),
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({ tabName: PropTypes.string, tabContent: PropTypes.node })
  ).isRequired,
  justify: PropTypes.bool,
};

Tabs.defaultProps = {
  className: '',
  border: 'default',
  justify: true,
};
