import React from 'react';
import { Collapse } from 'reactstrap';
import { matchPath, withRouter } from 'react-router';
import { compose, withState, withProps, withHandlers, pure } from 'recompose';
import PropTypes from 'prop-types';

const enhance = compose(
  withRouter,
  withState('isOpen', 'setOpen', props => {
    const {
      location: { pathname },
      route,
    } = props;

    return !!matchPath(pathname, route);
  }),
  withHandlers({
    toggle: props => () => {
      props.setOpen(!props.isOpen);
      document.activeElement.blur();
    },
  }),
  withProps(props => ({
    // className: classNames(props.className, 'sidebar__category-wrap', {
    //   'sidebar__category-wrap--open': props.collapse,
    // }),
    className: props.isOpen
      ? 'sidebar__category-wrap--open sidebar__category-wrap'
      : 'sidebar__category-wrap',
  })),
  pure
);

const SidebarCategory = ({
  title,
  icon,
  isNew,
  children,
  className,
  toggle,
  isOpen,
}) => (
  <div className={className}>
    <button
      className="sidebar__link sidebar__category"
      onClick={toggle}
      type="button"
    >
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">
        {title}
        {isNew && <span className="sidebar__category-new" />}
      </p>
      <span className="sidebar__category-icon lnr lnr-chevron-right" />
    </button>
    <Collapse isOpen={isOpen} className="sidebar__submenu-wrap">
      <ul className="sidebar__submenu">
        <div>{children}</div>
      </ul>
    </Collapse>
  </div>
);
SidebarCategory.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isNew: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
SidebarCategory.defaultProps = {
  icon: '',
  isNew: false,
};

export default enhance(SidebarCategory);
