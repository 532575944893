import states from './states';

const update = (items, u) => ({
  totalCount: u(items).length,
  items: u(items),
});

const replace = (items, payload) =>
  update(items, i => [...i.filter(x => x.id !== payload.id), payload]);

export default ({
  GET_LIST,
  DELETE_ITEM,
  UPDATE,
  GET,
  CREATE,
  CREATE_MANY,
  RESET,
}) => ({
  [GET_LIST]: (_, payload) => ({
    ...payload,
    status: states.loaded,
  }),
  [DELETE_ITEM]: ({ items, ...state }, _, meta) => ({
    ...state,
    ...update(items, i => i.filter(x => x.id !== meta.id)),
    status: states.loaded,
  }),
  [CREATE]: ({ items, ...state }, payload) => ({
    ...state,
    ...update(items, i => [...i, payload]),
    status: states.loaded,
  }),
  [CREATE_MANY]: ({ items, ...state }, payload) => ({
    ...state,
    ...replace(items, i => [...i, payload]),
    status: states.loaded,
  }),
  [UPDATE]: ({ items, ...state }, payload) => ({
    ...state,
    ...replace(items, payload),
    status: states.loaded,
  }),
  [GET]: ({ items, ...state }, payload) => ({
    ...state,
    ...update(items, i => [...i, payload]),
    status: states.loaded,
  }),
  [RESET]: ({ items, ...state }, payload) => ({
    ...state,
    ...replace(items, payload),
    status: states.loaded,
  }),
  default: state => state,
});
