import { createReducer } from 'redux-act';
import { configLoaded } from './actions';

const initialState = {};

export default createReducer(
  {
    [configLoaded]: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);
