import React, { Component } from 'react';
import './PageToolbar.scss';
import { Col, Collapse as ReactstrapCollapse, Row } from 'reactstrap';
import DotsVertical from 'mdi-react/DotsVerticalIcon';
import PropTypes from 'prop-types';
import Button from 'components/Button';

class PageToolbar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    // eslint-disable-next-line react/destructuring-assignment
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
    document.activeElement.blur();
  }

  render() {
    const { search, filters, actions } = this.props;

    return (
      <Row className="page-toolbar align-items-center">
        {search && (
          <Col
            xs={{ size: '', order: 1 }}
            lg={4}
            className="toolbar-item search mr-auto"
          >
            <form className="form">
              <div className="form__form-group-field">
                {filters && (
                  <Button
                    type="button"
                    className="input-group-prepend d-lg-none text-muted"
                    icon={<DotsVertical />}
                    onClick={this.toggle}
                  />
                )}
                {search}
              </div>
            </form>
          </Col>
        )}
        {filters && (
          <ReactstrapCollapse
            // eslint-disable-next-line react/destructuring-assignment
            isOpen={this.state.collapse}
            className="col-12 order-3 col-lg-auto order-lg-2 d-lg-block toolbar-item filters"
          >
            <div className="collapse-contents">{filters}</div>
          </ReactstrapCollapse>
        )}
        {actions && (
          <Col xs={{ size: 'auto', order: 2 }} className="toolbar-item actions">
            {actions}
          </Col>
        )}
      </Row>
    );
  }
}

PageToolbar.propTypes = {
  search: PropTypes.node,
  filters: PropTypes.node,
  actions: PropTypes.node,
};

PageToolbar.defaultProps = {
  search: null,
  filters: null,
  actions: null,
};

export default PageToolbar;
