import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Secured } from 'infrastructure/security';
import { ErrorBoundary, ErrorPage } from 'infrastructure/error';

import Dashboard from 'containers/Dashboard/index';
import Layout from 'containers/Layout';
import LogIn from 'slices/Account/Loadable';
import MainWrapper from 'containers/App/MainWrapper';
import IdentityUser from 'slices/IdentityUser/Loadable';
import IdentityRole from 'slices/IdentityRole/Loadable';
import Submissions from 'slices/Submissions/Loadable';
import Exports from 'slices/Exports/components/Index';
import Reviews from 'slices/Reviews/Loadable';
import FileUploader from 'slices/Uploads/components/Index';

const wrappedRoutes = () => (
  <Secured>
    <React.Fragment>
      <Layout>
        <div className="container__wrap">
          <ErrorBoundary>
            <Route path="/" component={Dashboard} exact />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/identity/role" component={IdentityRole} />
            <Route path="/identity/user" component={IdentityUser} />
            <Route path="/submissions" component={Submissions} />
            <Route path="/exports" component={Exports} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/uploads/:type/:id/" component={FileUploader} />
          </ErrorBoundary>
        </div>
      </Layout>
    </React.Fragment>
  </Secured>
);

const AppRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default AppRouter;
