import { createMuiTheme } from '@material-ui/core/styles';
import variables from 'scss/1-settings/colors.scss';

const muiTheme = createMuiTheme({
  palette: {
    common: {
      black: '#333',
      white: '#fff',
    },
    background: {
      paper: 'rgba(246, 246, 246, 1)',
      default: 'rgba(246, 246, 246, 1)',
    },
    primary: {
      main: variables.purple,
    },
    secondary: {
      main: variables.green,
    },
    error: {
      main: variables.red,
    },
    brandRed: {
      main: variables.red,
    },
    text: {
      primary: 'rgba(51, 51, 51, 1)',
      secondary: 'rgba(136, 136, 136, 1)',
      disabled: 'rgba(153, 153, 153, 1)',
      hint: 'rgba(136, 136, 136, 1)',
    },
    default: {
      main: 'grey',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: { useNextVariants: true },
  variables,
});

export default muiTheme;
