/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Content, DataTable } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'infrastructure/redux/injectReducer';
import { withApi } from 'infrastructure/api';
import { compose, lifecycle } from 'recompose';
import { Col, Row } from 'reactstrap';
import { stateKey } from './constants';
import makeSelectStats from './selectors';
import reducer from './reducer';
import StatCard from './components/StatCard';

const mapDispatchToProps = (dispatch, props) => ({
  api: bindActionCreators(props.api.dashboard.dashboard, dispatch),
});

const mapStateToProps = createStructuredSelector({
  [stateKey]: makeSelectStats(),
});

const withReducer = injectReducer({ key: stateKey, reducer });
const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withApi,
  withReducer,
  withRedux,
  lifecycle({
    componentDidMount() {
      this.props.api.getDashboard();
    },
  })
);

const tableOptions = {
  paging: false,
  filtering: false,
  search: false,
  hideSummary: true,
};

const Dashboard = ({ ...props }) => (
  <Content title="Dashboard" className="dashboard">
    <Row>
      <Col
        xs={{ size: 12, offset: 0 }}
        sm={{ size: 12, offset: 0 }}
        md={{ size: 10, offset: 1 }}
        lg={{ size: 6, offset: 0 }}
      >
        <h2 className="card-header">User Stats</h2>
        <Row>
          <Col md={4} xl={4} lg={4} xs={12}>
            <StatCard total={props.dashboard.sessions} title="Sessions" />
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <StatCard total={props.dashboard.uniques} title="Uniques" />
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <StatCard total={props.dashboard.pageViews} title="Page Views" />
          </Col>
        </Row>
      </Col>
      <Col
        xs={{ size: 12, offset: 0 }}
        sm={{ size: 12, offset: 0 }}
        md={{ size: 10, offset: 1 }}
        lg={{ size: 6, offset: 0 }}
      >
        <h2 className="card-header">Application Stats</h2>
        <Row>
          <Col
            md={4}
            xl={4}
            lg={4}
            xs={12}
            className="applications-started-card"
          >
            <StatCard
              total={props.dashboard.applicationsStarted}
              title="Applications Started"
            />
          </Col>
          <Col
            md={4}
            xl={4}
            lg={4}
            xs={12}
            className="applications-completed-card"
          >
            <StatCard
              total={props.dashboard.applicationsCompleted}
              title="Applications Completed"
            />
          </Col>
          <Col
            md={4}
            xl={4}
            lg={4}
            xs={12}
            className="applications-completed-card"
          >
            <StatCard
              total={props.dashboard.applicationsReviewed}
              title="Applications Reviewed"
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <h2 className="card-header">Stage 2 Application Stats</h2>
        <Row>
          <Col xs={12} md={{ size: 6, offset: 0 }}>
            <Row>
              <Col xs={12} md={6} className="applications-completed-card">
                <StatCard
                  total={props.dashboard.stage2Applications}
                  title="Applications"
                />
              </Col>
              <Col xs={12} md={6} className="applications-completed-card">
                <StatCard
                  total={props.dashboard.stage2ApplicationsReviewed}
                  title="Applications Reviewed"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="mb-4 spacing-top">
      <Col md={12} xl={12} lg={12} xs={12}>
        <h3 className="text-center">Application Stages Completed</h3>
        <DataTable
          columns={[
            { title: 'Stage', field: 'variable' },
            {
              title: 'Number Completed',
              field: 'numberApplications',
              fieldToDisplay: 'numberApplicationsToDisplay',
            },
          ]}
          data={props.dashboard.completedStages}
          title=""
          options={tableOptions}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <h3 className="text-center">Stage 1 Reviews Completed By Reviewers</h3>
        <DataTable
          columns={[
            { title: 'Reviewer', field: 'variable' },
            {
              title: 'Number of Completed Reviews',
              field: 'numberApplications',
              fieldToDisplay: 'numberApplicationsToDisplay',
            },
          ]}
          data={props.dashboard.completedReviewsByReviewers}
          title=""
          options={tableOptions}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <h3 className="text-center">Stage 2 Reviews Completed By Reviewers</h3>
        <DataTable
          columns={[
            { title: 'Reviewer', field: 'variable' },
            {
              title: 'Number of Completed Reviews',
              field: 'numberApplications',
              fieldToDisplay: 'numberApplicationsToDisplay',
            },
          ]}
          data={props.dashboard.completedStage2ReviewsByReviewers}
          title=""
          options={tableOptions}
        />
      </Col>
    </Row>
    <Row className="mb-4 spacing-top">
      <Col md={6} xl={6} lg={6} xs={12}>
        <h3 className="text-center">Number of Applications by State</h3>
        <DataTable
          columns={[
            { title: 'State', field: 'variable' },
            {
              title: 'Number of Applications',
              field: 'numberApplications',
              fieldToDisplay: 'numberApplicationsToDisplay',
            },
          ]}
          data={props.dashboard.applicationsByState}
          title=""
          options={tableOptions}
        />
      </Col>
      <Col md={6} xl={6} lg={6} xs={12}>
        <h3 className="text-center">Number of Applications by How Heard</h3>
        <DataTable
          columns={[
            { title: 'How Heard', field: 'variable' },
            {
              title: 'Number of Applications',
              field: 'numberApplications',
              fieldToDisplay: 'numberApplicationsToDisplay',
            },
          ]}
          data={props.dashboard.applicationsByHowHeard}
          title=""
          options={tableOptions}
        />
      </Col>
    </Row>
  </Content>
);

export default enhance(Dashboard);
