import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Collapse as ReactstrapCollapse } from 'reactstrap';
import './Collapse.scss';

class Collapse extends Component {
  constructor(props) {
    const { defaultOpen } = props;
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: defaultOpen };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
    document.activeElement.blur();
  }

  render() {
    const { collapse } = this.state;
    const { children, className, customTrigger, title } = this.props;

    const CollapseTrigger = (
      <div>
        <h4 className="title">{title}</h4>
        <span className="icon lnr lnr-chevron-down" />
      </div>
    );

    return (
      <div className={classnames(className, 'collapse-container')}>
        <button
          className={classnames('unstyled-button', {
            open: collapse,
            closed: !collapse,
          })}
          color="primary"
          onClick={this.toggle}
          type="button"
        >
          {customTrigger || CollapseTrigger}
        </button>
        <ReactstrapCollapse isOpen={collapse}>
          <div className="collapse-content">{children}</div>
        </ReactstrapCollapse>
      </div>
    );
  }
}

Collapse.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  customTrigger: PropTypes.node,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
};

Collapse.defaultProps = {
  children: null,
  className: '',
  customTrigger: null,
  defaultOpen: false,
  title: '',
};

export default Collapse;
