import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const Review = Loadable({
  loader: () => import('./containers/ReviewPage'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./containers/ListPage'),
  loading: () => <Loading visible />,
});
