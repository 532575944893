import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectStats = state => state.dashboard || initialState;

const makeSelectStats = () =>
  createSelector(
    selectStats,
    d => d
  );

export default makeSelectStats;
