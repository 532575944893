import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import DropZone from 'components/Form/DropZone';
import FormRow from 'components/Form/FormRow';
import Button from 'components/Button';
import AssetBrowser from 'components/Assets/AssetBrowser';
import './AssetField.scss';

class AssetField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;
    const { label } = this.props;
    return (
      <FormRow
        label={label}
        className="asset-field"
        align="top"
        helpText="Please select a portrait image, JPG or PNG"
      >
        <Row>
          <Col md="6">
            <DropZone name="asset" />
          </Col>
          <Col md="6">
            <Button
              color="light"
              block
              className="select-from-assets"
              onClick={this.toggle}
            >
              <span className="lnr lnr-folder" /> Select from Assets
            </Button>
            <Modal
              className="asset-picker-modal"
              isOpen={isOpen}
              size="lg"
              toggle={this.toggle}
            >
              <Button
                className="close-btn btn-icon rounded-pill"
                color="light"
                onClick={this.toggle}
                type="button"
                icon={<span className="lnr lnr-cross" />}
                size="lg"
              />
              <ModalBody>
                <AssetBrowser />
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </FormRow>
    );
  }
}

AssetField.propTypes = {
  label: PropTypes.string,
};

AssetField.defaultProps = {
  label: 'Asset',
};

export default AssetField;
