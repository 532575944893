import innerApiManger from './apiManager';

export function setApi(api) {
  innerApiManger.setApi(api);
}
export const apiManager = innerApiManger;

export { default as withApi } from './withApi';
export { default as createApiReducer } from './createApiReducer';
export { default as states } from './states';

export { default as createHandlers } from './createHandlers';
export { default as createEntityHandlers } from './createEntityHandlers';
export { default as createHydrate } from './createHydrate';

export const initialState = {
  result: [],
  totalCount: 0,
  status: 'none',
};
