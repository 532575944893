import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withApi } from 'infrastructure/api';
import TopbarMenuLink from './TopbarMenuLink';

const clearMenu = () => ({ type: 'CLEAR_MENU' });

const enhance = compose(
  withApi,
  connect(
    null,
    (dispatch, props) => ({
      api: {
        auth: bindActionCreators(props.api.auth.auth, dispatch),
      },
      clearMenu: bindActionCreators(clearMenu, dispatch),
    })
  )
);

export default enhance(
  class TopbarProfile extends PureComponent {
    static propTypes = {
      api: PropTypes.shape().isRequired,
      clearMenu: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      this.state = {
        collapse: false,
      };
    }

    toggle = () => {
      const { collapse } = this.state;
      this.setState({ collapse: !collapse });
    };

    logout = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.api.auth.logout();
      // eslint-disable-next-line react/destructuring-assignment
      this.props.clearMenu();
    };

    render() {
      const { collapse } = this.state;
      return (
        <div className="topbar__profile">
          <button
            type="button"
            className="topbar__avatar"
            onClick={this.toggle}
          >
            <p className="topbar__avatar-name">Settings</p>
            {/* <img className="topbar__avatar-img" src={Avatar} alt="avatar" /> */}
            {/* <p className="topbar__avatar-name">Jane Smith</p> */}
            <DownIcon className="topbar__icon" />
          </button>
          {collapse && (
            <button
              type="button"
              className="topbar__back"
              onClick={this.toggle}
            />
          )}
          <Collapse isOpen={collapse} className="topbar__menu-wrap">
            <div className="topbar__menu">
              {/* <TopbarMenuLink
                title="Settings"
                icon="cog"
                path="/user/settings"
              /> */}
              <TopbarMenuLink
                title="Log Out"
                icon="exit-right"
                path="/login"
                onClick={() => this.logout()}
              />
            </div>
          </Collapse>
        </div>
      );
    }
  }
);
