import { createReducer } from 'redux-act';
import states from './states';

const selectHandler = (t, handlers, def) =>
  handlers[t] || def || handlers.default;

const canHandle = (type, handlers) => {
  const handler = selectHandler(type, handlers);

  return handler !== handlers.default;
};

export default (handlers, initialState = {}) => {
  const reducer = createReducer(
    {
      API_REQUEST: (state, _, meta) => {
        if (!canHandle(meta.type, handlers)) {
          return state;
        }

        return {
          ...state,
          status: states.loading,
        };
      },
      API_SUCCESS: (state, payload, meta) => {
        if (!canHandle(meta.type, handlers)) {
          return state;
        }
        const handler = selectHandler(meta.type, handlers);

        return handler(state, payload, meta);
      },
      API_FAILURE: (state, payload, meta) => {
        if (!canHandle(meta.type, handlers)) {
          return state;
        }

        return {
          ...state,
          error: payload,
          status: states.error,
        };
      },
    },
    initialState
  );

  return reducer;
};
