import React from 'react';
import classnames from 'classnames';
import { visibility } from 'shared/enhancers';
import './Loading.scss';

const Loading = visibility(({ className, style = {} }) => (
  <div
    style={{
      width: '100%',
      ...style,
    }}
    className={classnames('spinner-container', 'text-center', className)}
  >
    <div className="load__icon-wrap">
      <svg className="load__icon">
        <path fill="#8D61C3" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    </div>
  </div>
));

export default Loading;
