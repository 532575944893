import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderComponent, lifecycle } from 'recompose';
import { withRouter } from 'react-router';
import URI from 'urijs';

import { Loading } from 'components';

const mapStateToProps = ({ account, menu }) => ({
  account,
  menu,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  branch(
    p => !p.account || !p.account.authenticated,
    renderComponent(
      lifecycle({
        componentDidMount() {
          this.props.history.push(
            `/login?returnUrl=${this.props.location.pathname}`
          );
        },
      })(() => <Loading visible />)
    )
  ),
  branch(
    p =>
      p.account &&
      p.account.authenticated &&
      p.menu &&
      p.menu.items &&
      p.menu.items.length,
    renderComponent(
      lifecycle({
        componentDidMount() {
          const {
            children,
            location: { pathname },
            menu: { items },
          } = this.props;
          const uri = new URI(pathname);
          const currentRoute = uri.segment()[0];

          const allowedRoutes = items.map(x => {
            const allowedUri = new URI(x.url);
            return allowedUri.segment()[0];
          });

          if (!allowedRoutes.includes(currentRoute)) {
            this.props.history.push(`/${allowedRoutes[0]}`);
            return <Loading visible />;
          }

          return React.Children.only(children);
        },
      })(props => React.Children.only(props.children))
    )
  )
);

const Secured = ({ children }) => React.Children.only(children);

export default enhance(Secured);
