// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { compose } from 'recompose';
import history from 'infrastructure/history';

import injectSaga from 'infrastructure/redux/injectSaga';
import { DAEMON } from 'infrastructure/redux/constants';

import Loading from 'components/Loading';

import { saga as errorSaga } from 'infrastructure/error';
import { saga as securitySaga } from 'infrastructure/security';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';

import AppRouter from 'Router';
import ScrollToTop from './ScrollToTop';
import saga from './saga';

const withSaga = compose(
  injectSaga({
    key: 'error',
    saga: errorSaga,
    mode: DAEMON,
  }),
  injectSaga({
    key: 'security',
    saga: securitySaga,
    mode: DAEMON,
  }),
  injectSaga({ key: 'app', saga, mode: DAEMON })
);

const enhanceContent = compose(
  withSaga,
  connect(state => ({
    theme: state.layout.theme.theme,
  }))
);

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: 'c',
});

const Content = enhanceContent(({ theme }) => (
  <JssProvider generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      <AppRouter />
    </MuiThemeProvider>
  </JssProvider>
));

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    const { store } = this.props;

    return (
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop>
            <>
              <Loading visibile={!loaded} loading={loading} />
              <Content />
            </>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}
App.propTypes = {
  store: PropTypes.shape({}).isRequired,
};

export default hot(module)(App);
