import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import './ToggleButton.scss';

// START: Based on shared/components/form/ToggleButton
// Sets type on button
class ToggleButtonField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  };

  static defaultProps = {
    defaultChecked: false,
    disabled: false,
  };

  componentDidMount() {
    const { onChange, defaultChecked } = this.props;

    onChange(defaultChecked);
  }

  render() {
    const { name, disabled, value, onChange } = this.props;

    return (
      <div className="toggle-btn">
        <input
          className="toggle-btn__input"
          type="checkbox"
          name={name}
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <button
          className="toggle-btn__input-label"
          onClick={() => onChange(!value)}
          type="button"
        >
          Toggle
        </button>
      </div>
    );
  }
}

const renderToggleButtonField = ({ input, defaultChecked, disabled }) => (
  <ToggleButtonField
    {...input}
    defaultChecked={defaultChecked}
    disabled={disabled}
  />
);

renderToggleButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

renderToggleButtonField.defaultProps = {
  defaultChecked: false,
  disabled: false,
};
// END

const ToggleButton = props => {
  const { name } = props;
  return (
    <Field
      name={name}
      render={({ form, field, ...p }) =>
        renderToggleButtonField({
          input: {
            ...field,
            onChange: v => {
              form.setFieldValue(name, v);
              props.onChange(v);
            },
          },
          ...p,
        })
      }
    />
  );
};
ToggleButton.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};
ToggleButton.defaultProps = {
  name: '',
  onChange: () => {},
};

export default ToggleButton;
