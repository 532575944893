/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@material-ui/core';
import ConfirmationButton from 'components/Button/ConfirmationButton';
import Button from '../Button/Button';

/* eslint-enable no-unused-vars */

class MTableActions extends React.Component {
  renderButton(action, index) {
    if (typeof action === 'function') {
      action = action(this.props.data);
      if (!action) {
        return null;
      }
    }

    const onClick = event => {
      if (action.onClick) {
        action.onClick(event, this.props.data);
        if (event && event.stopPropagation) {
          event.stopPropagation();
        }
      }
    };

    const disabled = action.calculateDisabled
      ? action.calculateDisabled(this.props.data)
      : action.disabled;

    const button = (
      <Button
        key={action.icon + '' + index}
        color={action.btnColor}
        block
        className={action.className}
        disabled={disabled}
        onClick={onClick}
      >
        {action.icon === '' ? '' : <Icon>{action.icon}</Icon>}
        {action.calculateTitle
          ? action.calculateTitle(this.props.data)
          : action.title}
      </Button>
    );

    if (action.tooltip && !disabled) {
      return (
        <Tooltip
          title={action.tooltip}
          key={action.tooltip + '' + index}
          placement="bottom"
        >
          {button}
        </Tooltip>
      );
    }

    if (action.hasConfirmation) {
      return (
        <ConfirmationButton
          callback={onClick}
          title={action.alertTitle}
          description={action.alertDescription}
        >
          <Button
            key={action.icon + '' + index}
            color={action.btnColor}
            block
            className={action.className}
            disabled={disabled}
          >
            {action.icon === '' ? '' : <Icon>{action.icon}</Icon>}
            {action.calculateTitle
              ? action.calculateTitle(this.props.data)
              : action.title}
          </Button>
        </ConfirmationButton>
      );
    }
    return button;
  }

  render() {
    if (this.props.actions) {
      return this.props.actions.map((action, index) =>
        this.renderButton(action, index)
      );
    }

    return null;
  }
}

MTableActions.defaultProps = {
  actions: [],
  data: {},
};

MTableActions.propTypes = {
  actions: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default MTableActions;
/* eslint-enable prefer-template */
/* eslint-enable react/destructuring-assignment */
/* eslint-enable react/default-props-match-prop-types */
/* eslint-enable react/forbid-prop-types */
/* eslint-enable react/require-default-props */
/* eslint-enable no-param-reassign */
