// import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  getContext,
  withProps,
  wrapDisplayName,
  setDisplayName,
} from 'recompose';
import getInjectors from './reducerInjector';

export default ({ key, reducer }) => Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, 'withReducer')),
    getContext({
      store: PropTypes.object.isRequired,
    }),
    withProps(props => ({
      injectReducer: getInjectors(props.store),
    })),
    lifecycle({
      componentDidMount() {
        const { injectReducer } = this.props;

        injectReducer.inject(key, reducer);
      },
    })
  )(Component);
