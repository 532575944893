/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import {
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import { CsvBuilder } from 'filefy';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Row, Input } from 'reactstrap';
import SearchIcon from 'mdi-react/SearchIcon';
import { Button } from 'components';
import './TableToolbar.scss';
/* eslint-enable no-unused-vars */

class MTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsButtonAnchorEl: null,
      exportButtonAnchorEl: null,
    };
  }

  exportCsv = () => {
    const columns = this.props.columns.filter(
      columnDef => !columnDef.hidden && columnDef.field
    );

    const data = this.props.renderData.map(rowData =>
      columns.map(columnDef => rowData[columnDef.field])
    );

    // eslint-disable-next-line no-unused-vars
    const builder = new CsvBuilder(
      (this.props.exportFileName || this.props.title || 'data') + '.csv'
    )
      .setDelimeter(this.props.exportDelimiter)
      .setColumns(columns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile();

    this.setState({ exportButtonAnchorEl: null });
  };

  renderSearch() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };
    if (this.props.search) {
      return (
        <Col md={6} lg={4}>
          <form className="form">
            <div className="form__form-group-field">
              <Input
                className="search-input"
                name="tableSearch"
                placeholder={localization.searchTooltip}
                type="search"
                value={this.props.searchText}
                onChange={event =>
                  this.props.onSearchChanged(event.target.value)
                }
              />
              <Button
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<SearchIcon />}
              />
            </div>
          </form>
        </Col>
      );
    } else {
      return null;
    }
  }

  renderDefaultActions() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };

    return (
      <div>
        {this.props.columnsButton && (
          <span>
            <Tooltip title={localization.showColumnsTitle}>
              <IconButton
                color="inherit"
                onClick={event =>
                  this.setState({ columnsButtonAnchorEl: event.currentTarget })
                }
                aria-label={localization.showColumnsAriaLabel}
              >
                <this.props.icons.ViewColumn />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={this.state.columnsButtonAnchorEl}
              open={Boolean(this.state.columnsButtonAnchorEl)}
              onClose={() => this.setState({ columnsButtonAnchorEl: null })}
            >
              <MenuItem
                key="text"
                disabled
                style={{ opacity: 1, fontWeight: 600, fontSize: 12 }}
              >
                {localization.addRemoveColumns}
              </MenuItem>
              {this.props.columns.map((col, index) => (
                <MenuItem
                  key={col.tableData.id}
                  disabled={col.removable === false}
                >
                  <FormControlLabel
                    label={col.title}
                    control={
                      <Checkbox
                        checked={!col.hidden}
                        onChange={(event, checked) => {
                          // eslint-disable-next-line prefer-destructuring
                          const columns = this.props.columns;
                          columns[index].hidden = !checked;
                          this.props.onColumnsChanged(columns);
                        }}
                      />
                    }
                  />
                </MenuItem>
              ))}
            </Menu>
          </span>
        )}
        {this.props.exportButton && (
          <span>
            <Tooltip title={localization.exportTitle}>
              <IconButton
                color="inherit"
                onClick={event =>
                  this.setState({ exportButtonAnchorEl: event.currentTarget })
                }
                aria-label={localization.exportAriaLabel}
              >
                <this.props.icons.Export />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={this.state.exportButtonAnchorEl}
              open={Boolean(this.state.exportButtonAnchorEl)}
              onClose={() => this.setState({ exportButtonAnchorEl: null })}
            >
              <MenuItem key="export-csv" onClick={this.exportCsv}>
                {localization.exportName}
              </MenuItem>
            </Menu>
          </span>
        )}
        <this.props.components.ToolbarActions
          actions={
            this.props.actions && this.props.actions.filter(a => a.isFreeAction)
          }
        />
      </div>
    );
  }

  renderSelectedActions() {
    return (
      <React.Fragment>
        <div className="submissions--actions">
          <this.props.components.ToolbarActions
            actions={this.props.actions.filter(a => !a.isFreeAction)}
            data={this.props.selectedRows}
          />
        </div>
      </React.Fragment>
    );
  }

  renderActions() {
    return (
      <div>
        {this.props.selectedRows && this.props.selectedRows.length > 0
          ? this.renderSelectedActions()
          : this.renderDefaultActions()}
      </div>
    );
  }

  render() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };

    const total = this.props.renderData.length;

    const pageSize = +(this.props.options.pageSize || 5);
    const start = pageSize * +(this.props.options.page || 0);
    let end = start + pageSize;
    if (end > total) {
      end = total;
    }

    const selectedRowsCount =
      this.props.selectedRows && this.props.selectedRows.length > 0
        ? localization.nRowsSelected.replace(
            '{0}',
            this.props.selectedRows.length
          )
        : this.props.title;

    const pagingSummary = localization.pagingSummary
      .replace('{0}', start + 1)
      .replace('{1}', end)
      .replace('{2}', total);

    return (
      <Row className="table-toolbar align-items-center">
        {this.renderSearch()}
        {!this.props.options.hideSummary && (
          <Col xs="auto">
            <h5>{pagingSummary}</h5>
          </Col>
        )}
        <Col xs={12} className="col-lg">
          <Row className="align-items-center justify-content-end">
            <Col xs="auto">
              <h5>{selectedRowsCount}</h5>
            </Col>
            <Col xs="auto">{this.renderActions()}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

MTableToolbar.defaultProps = {
  actions: [],
  columns: [],
  columnsButton: false,
  localization: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export as CSV',
    searchTooltip: 'Search',
    pagingSummary: 'Showing {0} to {1} of {2}.',
  },
  search: true,
  searchText: '',
  selectedRows: [],
  title: 'No Title!',
};

MTableToolbar.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  columnsButton: PropTypes.bool,
  localization: PropTypes.object.isRequired,
  onColumnsChanged: PropTypes.func.isRequired,
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedRows: PropTypes.array,
  title: PropTypes.string.isRequired,
  renderData: PropTypes.array,
  exportButton: PropTypes.bool,
  exportDelimiter: PropTypes.string,
  exportFileName: PropTypes.string,
  options: PropTypes.shape().isRequired,
};

export default MTableToolbar;
/* eslint-enable react/default-props-match-prop-types */
/* eslint-enable react/require-default-props */
/* eslint-enable react/forbid-prop-types */
/* eslint-enable react/no-array-index-key */
/* eslint-enable react/destructuring-assignment */
/* eslint-enable prefer-template */
/* eslint-enable no-else-return */
