import states from './states';

const update = (items, u) => ({
  totalCount: u(items).length,
  result: u(items),
});

export default ({
  GET_LIST,
  DELETE_ITEM,
  UPDATE,
  GET,
  CREATE,
  CREATE_MANY,
  RESET,
}) => ({
  [GET_LIST]: (_, payload) => ({
    ..._,
    result: payload.normalized.result,
    totalCount: payload.normalized.result.length,
    status: states.loaded,
  }),
  [DELETE_ITEM]: ({ result, ...state }, _, meta) => ({
    ...state,
    ...update(result, i => i.filter(x => x !== meta.id)),
    status: states.loaded,
  }),
  [CREATE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [CREATE_MANY]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, ...payload.normalized.result]),
    status: states.loaded,
  }),
  [UPDATE]: ({ result, ...state }) => ({
    ...state,
    ...update(result, i => [...i]),
    status: states.loaded,
  }),
  [GET]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [RESET]: ({ result, ...state }) => ({
    ...state,
    ...update(result, i => [...i]),
    status: states.loaded,
  }),
  default: state => state,
});
