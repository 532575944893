import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./containers/FormPage'),
  loading: () => <Loading visible />,
});
