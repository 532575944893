import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './DefinitionList.scss';

const DefinitionList = props => {
  const { tableLayout, terms } = props;
  return (
    <dl className={classnames({ 'definition-table': tableLayout })}>
      {terms.map(x => (
        <div key={x.key}>
          <dt>{x.name}: </dt>
          <dd>{x.definition}</dd>
        </div>
      ))}
    </dl>
  );
};

DefinitionList.propTypes = {
  tableLayout: PropTypes.bool,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      name: PropTypes.string,
      definition: PropTypes.string,
    })
  ),
};

DefinitionList.defaultProps = {
  tableLayout: false,
  terms: [],
};

export default DefinitionList;
