import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import Icon from 'mdi-react/CloseCircleOutlineIcon';

const ErrorAlert = ({ canRetry }) => (
  <Alert color="danger" className="alert--bordered" isOpen>
    <div className="alert__icon">
      <Icon />
    </div>
    <div className="alert__content">
      <p>
        <span className="bold-text">Whoops!!</span> Something went wrong!{' '}
      </p>
      {canRetry && (
        <p>
          <a href="/">Try again</a>
        </p>
      )}
    </div>
  </Alert>
);
ErrorAlert.propTypes = {
  canRetry: PropTypes.bool,
};
ErrorAlert.defaultProps = {
  canRetry: false,
};

export default ErrorAlert;
