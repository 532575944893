import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import renderCheckBoxField from 'shared/components/form/CheckBox';

const CheckboxField = props => {
  const { className, label, name, onChange, validate } = props;
  return (
    <Field
      {...props}
      type="checkbox"
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
      render={({ field, ...p }) =>
        renderCheckBoxField({
          className,
          input: field,
          label,
          onChange,
          ...p,
        })
      }
    />
  );
};
CheckboxField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.arrayOf(PropTypes.shape({})),
};
CheckboxField.defaultProps = {
  className: 'colored-click',
  label: '',
  name: '',
  onChange: null,
  validate: [],
};

export default CheckboxField;
