import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import URI from 'urijs';
import { NavLink } from 'react-router-dom';

const sidebarLinkPropTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

const LinkContent = ({ title, icon, newLink }) => (
  <li className="sidebar__link">
    {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
    <p className="sidebar__link-title">
      {title}
      {newLink ? (
        <Badge className="sidebar__link-badge">
          <span>New</span>
        </Badge>
      ) : (
        ''
      )}
    </p>
  </li>
);
LinkContent.propTypes = {
  ...sidebarLinkPropTypes,
};

const ExternalLink = ({ route, ...props }) => (
  <a href={route} className="sidebar__link-title">
    <LinkContent {...props} />
  </a>
);
ExternalLink.propTypes = {
  ...sidebarLinkPropTypes,
};

const InternalLink = ({ route, onClick, ...props }) => (
  <NavLink to={route} onClick={onClick} activeClassName="sidebar__link-active">
    <LinkContent {...props} />
  </NavLink>
);
InternalLink.propTypes = {
  ...sidebarLinkPropTypes,
};

const SidebarLink = ({ route, ...props }) =>
  URI(route).protocol() ? (
    <ExternalLink route={route} {...props} />
  ) : (
    <InternalLink route={route} {...props} />
  );

export default SidebarLink;
