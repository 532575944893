import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import MinusCircleIcon from 'mdi-react/MinusCircleIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import Button from 'components/Button';
import './ListField.scss';

function ListField({
  component: Component,
  label,
  lineItem,
  name,
  singleField,
  ...props
}) {
  return (
    <FieldArray
      name={name}
      render={({ form, push, remove }) => {
        const values = form.values[name] || [];

        return (
          <div className="list-field">
            {singleField
              ? values.map((item, index) => (
                  <div className="single-field">
                    <Component
                      key={`${name + index}`}
                      index={index}
                      name={`${name}[${index}]`}
                    />
                    <Button
                      className="btn-light icon rounded-pill fieldset-right-icon"
                      buttonStyle="link"
                      onClick={() => remove(index)}
                      outline
                      color="danger"
                      title="Remove"
                    >
                      <MinusCircleIcon className="text-danger" />
                    </Button>
                  </div>
                ))
              : values.map((item, index) => (
                  <fieldset className="fieldset">
                    <legend>
                      {lineItem} {index + 1}
                      <Button
                        className="btn-light icon rounded fieldset-right-icon"
                        buttonStyle="link"
                        onClick={() => remove(index)}
                        outline
                        color="danger"
                        title="Remove"
                      >
                        <MinusCircleIcon className="text-danger" />
                      </Button>
                    </legend>
                    <Component
                      key={`${name + index}`}
                      index={index}
                      name={`${name}[${index}]`}
                    />
                  </fieldset>
                ))}
            <Button
              className="btn-add-field"
              color="primary"
              icon={<PlusIcon />}
              onClick={() => push({})}
            >
              Add
            </Button>
          </div>
        );
      }}
      {...props}
    />
  );
}

ListField.propTypes = {
  component: PropTypes.node.isRequired,
  lineItem: PropTypes.string,
  name: PropTypes.string.isRequired,
  singleField: PropTypes.bool,
};

ListField.defaultProps = {
  lineItem: 'Item',
  singleField: false,
};

export default ListField;
