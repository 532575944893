import $script from 'scriptjs';
import URI from 'urijs';
import { RSAA } from 'redux-api-middleware';
import { configLoaded } from 'infrastructure/config';
import { setApi } from 'infrastructure/api';

function getConfig(container) {
  if (!getConfig.config && container.config) {
    getConfig.config = container.config;
  }

  return getConfig.config;
}

function getApiFactory(container) {
  if (!getApiFactory.fac && container.buildApiClient) {
    getApiFactory.fac = container.buildApiClient;
  }

  return getApiFactory.fac;
}

const handleConfigLoad = (
  container,
  store,
  get = getConfig,
  fallback = () => {}
) => () => {
  const config = get(container);
  if (config) {
    store.dispatch(configLoaded(config));
    store.dispatch({
      type: 'STARTED',
    });
    $script.done('config-loaded');
  } else {
    // eslint-disable-next-line no-console
    console.error('There was an error attempting to load config.');
    fallback(store);
  }
};

const loadApiProxy = config => {
  $script(
    URI(config.apiUrl)
      .segment('/Abp/ServiceProxyScript')
      .addQuery({ type: 'es5redux', useCache: true })
      .toString(),
    'api'
  );
};

const handleApiLoad = (contaier, store, start, fallback = () => {}) => () => {
  const factory = getApiFactory(contaier);
  if (factory) {
    const { apiUrl } = getConfig(global);
    const api = factory({
      buildUrl: url => `${apiUrl}${url}`,
      RSAA,
    });

    setApi(api);
    start(store);
  } else {
    // eslint-disable-next-line no-console
    console.error('There was an error attempting to load API.');
    fallback(store);
  }
};

const prodStart = (start, store) => {
  $script.ready('config', handleConfigLoad(global, store));

  $script.ready('config-loaded', () => {
    loadApiProxy(getConfig(global));
  });

  $script.ready('api', handleApiLoad(global, store, start));

  const externals = ['/config.js'].filter(x => x);

  $script(externals, 'config');
};

const devStart = (start, store, fallback) => {
  if (process.env.NODE_ENV !== 'production') {
    $script.ready('config-loaded', () => {
      loadApiProxy(getConfig(global));
    });
    $script.ready('api', handleApiLoad(global, store, start, fallback));

    // eslint-disable-next-line global-require
    const config = require('../../config').default;
    getConfig.config = config;
    handleConfigLoad({ config }, store, undefined, fallback)();
  }
};

const select = () =>
  process.env.NODE_ENV === 'production' ? prodStart : devStart;

export default (render, store, fallback = () => {}) => {
  select()(render, store, fallback);
};
