/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-console */
import AwsS3Multipart from '@uppy/aws-s3-multipart/lib/index';
import { Uppy } from '@uppy/core/lib/index';
import DragDrop from '@uppy/react/lib/DragDrop';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withApi } from 'infrastructure/api';
import { withConfig } from 'infrastructure/config';

import '@uppy/core/dist/style.css';

class FileUploader extends React.Component {
  timer = null;

  constructor(props) {
    super(props);
    const { config, match, field } = props;
    this.uppyOptions = {
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 5000000000,
        allowedFileTypes: ['application/pdf'],
        minNumberOfFiles: 1,
      },
      autoProceed: true,
    };
    this.awsOptions = {
      limit: 4,
      companionUrl: config.apiUrl,
      serverHeaders: {
        UserId: `${match.params.id}`,
        UploadType: field,
      },
    };
    this.uppy = new Uppy(this.uppyOptions).use(AwsS3Multipart, this.awsOptions);
    this.state = {
      uploadStatus: 'NOT_STARTED',
    };
    this.setUpEvents();
  }

  setUpEvents = () => {
    this.uppy.on('upload-progress', (file, progress) => {
      console.log(progress);
      this.setState({ uploadStatus: 'IN_PROGRESS' });
    });

    this.uppy.on('upload-success', () => {
      this.uppy.reset();
      this.setState({ uploadStatus: 'COMPLETE' });
    });

    this.uppy.on('upload-error', (file, error) => {
      const errorMessage =
        error && error.message ? error.message : 'Unknown error';
      console.log(errorMessage);
    });

    this.uppy.on('restriction-failed', (file, error) => {
      if (error && error.message) {
        const errorMessage = error.message.includes('application/pdf')
          ? 'You can only upload a .pdf file'
          : error.message;
        console.log(errorMessage);
      }
    });
  };

  render() {
    const { uploadStatus } = this.state;
    const { field } = this.props;
    return (
      <div>
        {uploadStatus === 'NOT_STARTED' && (
          <span className="btn btn-primary upload-btn btn-block">
            <DragDrop
              uppy={this.uppy}
              locale={{
                strings: {
                  dropHereOr: '%{browse}',
                  browse: field,
                },
              }}
            />
          </span>
        )}
        {uploadStatus === 'COMPLETE' && <span>Complete</span>}
      </div>
    );
  }
}

FileUploader.propTypes = {
  config: PropTypes.shape({}).isRequired,
  field: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const enhance = compose(
  withApi,
  withConfig,
  withRouter
);

export default enhance(FileUploader);
