const parseValidationErrors = errors =>
  errors.reduce(
    (r, c) => ({
      ...r,
      ...c.members.reduce(
        (o, member) => ({
          ...o,
          [member]: c.message,
        }),
        {}
      ),
    }),
    {}
  );

export default parseValidationErrors;
