import React from 'react';
import './FormActions.scss';
import { ButtonToolbar } from 'reactstrap';
import { Button } from 'components';
import { Fieldset } from 'components/Form';
import CheckIcon from 'mdi-react/CheckIcon';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';
import RestartIcon from 'mdi-react/RestartIcon';
import PropTypes from 'prop-types';

const FormActions = ({ isValid, isSubmitting, handleReset, setFieldValue }) => (
  <Fieldset className="form-actions--review">
    <ButtonToolbar className="form__button-toolbar">
      <Button
        disabled={isSubmitting}
        loading={isSubmitting}
        color="primary"
        type="submit"
        block
        icon={<ContentSaveIcon />}
      >
        Save
      </Button>
      <Button
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        color="primary"
        type="submit"
        icon={<CheckIcon />}
        onClick={() => setFieldValue('attemptSubmit', true)}
      >
        Submit
      </Button>
      <Button
        disabled={isSubmitting}
        onClick={handleReset}
        color="danger"
        type="button"
        icon={<RestartIcon />}
      >
        Reset
      </Button>
    </ButtonToolbar>
  </Fieldset>
);

FormActions.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
};

FormActions.defaultProps = {
  setFieldValue: () => {},
};

export default FormActions;
