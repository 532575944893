import { createSelector } from 'reselect';
import { matchPath } from 'react-router';
import { initialState } from './reducer';

export const makeSelectMenu = () => state => state.menu || initialState;

const makeSelectRouting = () => (_, { location, match }) => ({
  location,
  match,
});

const check = (pathname, route) => !!matchPath(pathname, route);

export const makeSelectCurrentPath = () =>
  createSelector(
    [makeSelectMenu(), makeSelectRouting()],
    (menu, { location, match }) => {
      const build = (c, r) => {
        const inPath = check(location.pathname, r.url);
        if (inPath) {
          const isCurrent = check(r.url, {
            ...match,
          });
          return [
            ...c,
            {
              ...r,
              isCurrent,
            },
            ...r.items.reduce(build, []),
          ];
        }

        return c;
      };

      const paths = menu.items.reduce(build, []);

      if (paths.length) {
        paths.unshift({ url: '/', displayName: 'Home' });
      }

      return paths;
    }
  );
