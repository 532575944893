import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Img.scss';

const Img = props => {
  const { alt, blurFill, containerClass, imageClass, src } = props;
  return (
    <div
      className={classnames('image-component', containerClass, {
        'blur-fill': blurFill,
      })}
    >
      <div className="image-size-container">
        <img src={src} alt={alt} className={imageClass} />
      </div>
      {blurFill && (
        <div
          className="background"
          style={blurFill && { backgroundImage: `url(${src})` }}
        />
      )}
    </div>
  );
};

Img.propTypes = {
  alt: PropTypes.string,
  blurFill: PropTypes.bool,
  containerClass: PropTypes.string,
  imageClass: PropTypes.string,
  src: PropTypes.string,
};

Img.defaultProps = {
  alt: '',
  blurFill: false,
  containerClass: '',
  imageClass: '',
  src: '',
};

export default Img;
