import React from 'react';
import './AssetToolbar.scss';
import { ButtonGroup, Col, Label, Input, Row } from 'reactstrap';
import Select from 'react-select';
import PlusIcon from 'mdi-react/PlusIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import variables from 'scss/1-settings/colors.scss';

const reactSelectTheme = theme => ({
  ...theme,
  borderRadius: '0.2rem',
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const AssetToolbar = props => {
  const { showSearch, showSort, showFilter, showUpload } = props;
  return (
    <Row className="asset-toolbar align-items-center">
      {showSearch && (
        <Col md={6} lg={4} className="toolbar-item">
          <form className="form">
            <div className="form__form-group-field">
              <Input
                className="seatch-input"
                name="tableSearch"
                placeholder="eg. Folder or File Name"
                type="search"
              />
              <Button
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<SearchIcon />}
              />
            </div>
          </form>
        </Col>
      )}
      <Col xs={12} className="col-xl">
        <Row className="align-items-center justify-content-end">
          {showSort && (
            <Col xs={12} md="auto" className="toolbar-item">
              <Row className="align-items-center">
                <Col xs="auto">
                  <Label htmlFor="sortBy">Sort By:</Label>
                </Col>
                <Col>
                  <Select
                    classNamePrefix="rs"
                    defaultValue={1}
                    name="sortBy"
                    openMenuOnFocus
                    options={[
                      { label: 'Date Added', value: 1 },
                      { label: 'Date Modified', value: 2 },
                      { label: 'Name', value: 3 },
                    ]}
                    theme={reactSelectTheme}
                    value={1}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {showFilter && (
            <Col xs={12} md="auto" className="toolbar-item">
              <ButtonGroup className="justified">
                <Button type="button" color="primary" size="sm">
                  All
                </Button>
                <Button type="button" color="primary" outline size="sm">
                  Folders
                </Button>
                <Button type="button" color="primary" outline size="sm">
                  Images
                </Button>
                <Button type="button" color="primary" outline size="sm">
                  Videos
                </Button>
              </ButtonGroup>
            </Col>
          )}
          {showUpload && (
            <Col xs="auto" className="toolbar-item">
              <Button type="button" icon={<PlusIcon />} color="primary">
                New
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

AssetToolbar.propTypes = {
  showSearch: PropTypes.bool,
  showSort: PropTypes.bool,
  showFilter: PropTypes.bool,
  showUpload: PropTypes.bool,
};

AssetToolbar.defaultProps = {
  showSearch: true,
  showSort: true,
  showFilter: true,
  showUpload: true,
};

export default AssetToolbar;
