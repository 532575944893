import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import renderDropZoneField from 'components/Form/DropZoneField';
import './DropZone.scss';

const defaultEmptyMessage = 'Drop a file here or click to upload';

const DropZopne = props => {
  const { className, fieldProps, emptyMessage, multiple, name } = props;
  return (
    <Field
      name={name}
      render={({ form, field }) =>
        renderDropZoneField({
          className,
          emptyMessage,
          fieldProps,
          input: {
            ...field,
            onChange: file => form.setFieldValue(name, file),
          },
          multiple,
        })
      }
    />
  );
};

DropZopne.propTypes = {
  className: PropTypes.string,
  emptyMessage: PropTypes.node,
  fieldProps: PropTypes.shape({}),
  multiple: PropTypes.bool,
  name: PropTypes.string,
};

DropZopne.defaultProps = {
  className: '',
  emptyMessage: defaultEmptyMessage,
  fieldProps: {
    accept: 'image/jpeg, image/png, image/gif',
  },
  multiple: false,
  name: '',
};

export default DropZopne;
