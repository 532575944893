import { withFormik } from 'formik';
import { compose } from 'recompose';
// import renderField from './renderField';

export default opts =>
  compose(
    withFormik({
      handleSubmit: (data, { props, ...form }) => props.onSubmit(data, form),
      ...opts,
    })
  );
