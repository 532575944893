// import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  getContext,
  withProps,
  wrapDisplayName,
  setDisplayName,
} from 'recompose';
import getInjectors from './sagaInjector';

export default ({ key, saga, mode }) => Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, 'withSaga')),
    getContext({
      store: PropTypes.object.isRequired,
    }),
    withProps(props => ({
      injectSaga: getInjectors(props.store),
    })),
    lifecycle({
      componentDidMount() {
        const { injectSaga } = this.props;

        injectSaga.inject(key, { saga, mode }, this.props);
      },

      componentWillUnmount() {
        const { injectSaga } = this.props;

        injectSaga.eject(key);
      },
    })
  )(Component);
