/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';

const ConfirmationButton = ({
  title,
  description,
  alertStyle,
  callback,
  children,
}) => {
  const onClick = () => {
    swal({
      title,
      text: description,
      className: alertStyle,
      buttons: ['Cancel', 'Yes, I’m sure'],
    }).then(confirmation => {
      if (confirmation) {
        callback.call();
      }
    });
  };

  return <div onClick={onClick}>{children}</div>;
};

ConfirmationButton.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alertStyle: PropTypes.oneOf(['success', 'warning']),
  callback: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ConfirmationButton.defaultProps = {
  callback: () => {},
  alertStyle: 'warning',
};

export default ConfirmationButton;
