import URI from 'urijs';
import fetch from 'isomorphic-fetch';
import browserHistory from 'infrastructure/history';

export const handler = () => (message, file, line, col, errorObject) => {
  const column = col || (global.event && global.event.errorCharacter);
  const { stack } = errorObject;

  const { pathname } = global.location;

  const redirect = () => {
    const returnUrl = URI(global.location.origin).segment(pathname);

    const url = URI('/')
      .resource('500')
      .search(`returnUrl=${returnUrl}`);

    browserHistory.push(url.toString());
  };

  const data = {
    message,
    file,
    line,
    column,
    stack,
    // TODO: Full state a good idea to send back?
    pathname,
  };

  try {
    const { apiUrl } = global.config;
    const request = new Request(`${apiUrl}/system/error`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(data),
    });

    fetch(request);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  } finally {
    redirect();
  }
};

export default handler;
