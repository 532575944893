import React from 'react';
import classnames from 'classnames';
import {
  Modal as ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Button } from 'components';
import './Modal.scss';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
    document.activeElement.blur();
  }

  render() {
    const {
      buttonContents,
      buttonOptions,
      className,
      children,
      color,
      footer,
      size,
      title,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <Button {...buttonOptions} onClick={this.toggle}>
          {buttonContents}
        </Button>
        <ModalContainer
          className={classnames(className, color)}
          isOpen={isOpen}
          size={size}
          toggle={this.toggle}
        >
          <Button
            className="close-btn btn-icon rounded-pill"
            color="light"
            onClick={this.toggle}
            type="button"
            icon={<span className="lnr lnr-cross" />}
          />
          {title && <ModalHeader>{title}</ModalHeader>}
          {children && <ModalBody>{children}</ModalBody>}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContainer>
      </React.Fragment>
    );
  }
}

Modal.propTypes = {
  buttonOptions: PropTypes.shape({}),
  buttonContents: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  footer: PropTypes.node,
  size: PropTypes.oneOf(['', 'sm', 'lg']),
  title: PropTypes.node,
};

Modal.defaultProps = {
  buttonOptions: null,
  buttonContents: '',
  className: '',
  children: null,
  color: null,
  footer: null,
  size: '',
  title: null,
};

export default Modal;
