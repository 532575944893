import { createApiReducer } from 'infrastructure/api';

export const initialState = { items: [] };

const handlers = {
  'system/menu/GET': (state, payload) => payload,
};

const apiReducer = createApiReducer(handlers, initialState);

const reducer = (state, action) => {
  if (action.type === 'CLEAR_MENU') {
    return initialState;
  }

  return apiReducer(state, action);
};

export default reducer;
