import { compose, setDisplayName, wrapDisplayName } from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = ({ config }) => ({
  config,
});

export default Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, 'withConfig')),
    connect(mapStateToProps)
  )(Component);
