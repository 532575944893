import PlusIcon from 'mdi-react/PlusIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

export default (handlers, props) => [
  {
    tooltip: 'New',
    icon: 'add_circle_outline',
    isFreeAction: true,
    onClick: () => handlers.new(props),
    toolbarIcon: PlusIcon,
    toolbarClassName: 'btn-create',
    toolbarButtonPriority: 'primary',
  },
  {
    tooltip: 'Duplicate',
    className: 'duplicate',
    icon: 'queue',
    isRowAction: true,
    onClick: (e, item) => handlers.copy(item, props),
  },
  {
    tooltip: 'Edit',
    className: 'edit',
    icon: 'edit',
    isRowAction: true,
    onClick: (e, item) => handlers.edit(item, props),
  },
  {
    tooltip: 'Delete',
    className: 'delete',
    icon: 'delete',
    isRowAction: true,
    isSelectedAction: true,
    onClick: (e, item) => handlers.delete(item, props),
    toolbarIcon: DeleteIcon,
    toolbarClassName: 'btn-delete',
    toolbarButtonPriority: 'danger',
  },
];
