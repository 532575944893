/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SidebarProps } from 'shared/prop-types/ReducerProps';

import { withMenu } from 'infrastructure/menu';
import Loading from 'components/Loading';

import Sidebar, {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from 'components/Sidebar';

import Topbar from './topbar/Topbar';

import {
  changeThemeToDark,
  changeThemeToLight,
  changeThemeToLightOnDark,
} from './theme';

const enhance = compose(
  withRouter,
  withMenu,
  connect(state => ({
    sidebar: state.layout.sidebar,
  }))
);

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    menu: PropTypes.shape().isRequired,
    children: PropTypes.shape().isRequired,
  };

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  changeToLightOnDark = () => {
    this.props.dispatch(changeThemeToLightOnDark());
  };

  render() {
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': this.props.sidebar.collapse,
    });

    const menuLoaded =
      this.props.menu && this.props.menu.items && this.props.menu.items.length;

    const homeButtonUrl = menuLoaded
      ? this.props.menu.items[0].url
      : '/dashboard';

    return menuLoaded ? (
      <>
        <div className={layoutClass}>
          <Topbar
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
            homeButtonUrl={homeButtonUrl}
          />
          <Sidebar
            sidebar={this.props.sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeToLightOnDark={this.changeToLightOnDark}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            menu={this.props.menu}
          />
        </div>
        {this.props.children}
      </>
    ) : (
      <Loading visible />
    );
  }
}

export default enhance(Layout);
/* eslint-enable react/destructuring-assignment */
