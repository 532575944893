import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

const StatCard = ({ icon, title, total }) => (
  <Card className="panel dashboard__card-widget" body>
    <div className="mobile-app-widget">
      <div className="mobile-app-widget__top-line--blue">
        <h2 className="mobile-app-widget__total-stat">{total}</h2>
        {icon}
      </div>
      <div className="mobile-app-widget__title">
        <h5>{title}</h5>
      </div>
    </div>
  </Card>
);

StatCard.propTypes = {
  icon: PropTypes.node,
  total: PropTypes.string,
  title: PropTypes.string,
};

StatCard.defaultProps = {
  icon: '',
  total: '0',
  title: '',
};

export default StatCard;
