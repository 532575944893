import React, { PureComponent } from 'react';
import { ChromePicker } from 'react-color';
import { UncontrolledPopover } from 'reactstrap';
import PropTypes from 'prop-types';
import './ColorPicker.scss';

class ChromeColorPickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      displayColorPicker: false,
      color: props.value || '#70bbfd',
    };
  }

  handleChange = color => {
    this.setState({ color: color.hex, rgb: color.rgb });
    this.props.onChange(color);
  };

  render() {
    const { name } = this.props;

    return (
      <div className="color-picker">
        <button className="color-picker__button" id={name} type="button">
          <p className="color-picker__color">{this.state.color}</p>
          <div
            className="color-picker__color-view"
            style={{ backgroundColor: this.state.color }}
          />
        </button>
        <UncontrolledPopover
          trigger="legacy"
          target={name}
          placement="bottom"
          className="color-picker__popover"
        >
          <ChromePicker color={this.state.color} onChange={this.handleChange} />
        </UncontrolledPopover>
      </div>
    );
  }
}

const renderChromeColorPickerField = props => (
  <div className="form__form-group-input-wrap">
    <ChromeColorPickerField {...props.input} />
    {props.meta.touched && props.meta.error && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
  </div>
);

renderChromeColorPickerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderChromeColorPickerField.defaultProps = {
  meta: null,
};

export default renderChromeColorPickerField;
