import { createApiReducer } from 'infrastructure/api';

export const initialState = {};

const update = (state, payload) => ({
  ...payload,
});

const handlers = {
  'dashboard/dashboard/GET_DASHBOARD': update,
};

const apiReducer = createApiReducer(handlers, initialState);

export default apiReducer;
