import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { matchPath, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose, withState, withHandlers } from 'recompose';
import classnames from 'classnames';
import FolderIcon from 'mdi-react/FolderIcon';
import Button from 'components/Button';
import './FolderListItem.scss';

const enhance = compose(
  withRouter,
  withState('isOpen', 'setOpen', props => {
    const {
      location: { pathname },
      route,
    } = props;

    return !!matchPath(pathname, route);
  }),
  withHandlers({
    toggle: props => () => {
      props.setOpen(!props.isOpen);
      // prevent the focus state hanging about
      document.activeElement.blur();
    },
  })
);

const FolderListItem = ({
  items,
  className,
  icon,
  isOpen,
  path,
  title,
  toggle,
  children,
}) => (
  <React.Fragment>
    <li className={classnames(className, 'folder-list-item')}>
      {icon}
      <Link to={path} className="folder-title">
        {title}
      </Link>
      {items && (
        <Button
          className={classnames('btn-icon', {
            open: isOpen,
          })}
          icon={<div className="lnr lnr-chevron-down" />}
          onClick={toggle}
          role="button"
          size="sm"
          type="button"
        />
      )}
    </li>
    {items && (
      <Collapse tag="ul" className="folder-list" isOpen={isOpen}>
        {children}
      </Collapse>
    )}
  </React.Fragment>
);

FolderListItem.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

FolderListItem.defaultProps = {
  items: null,
  className: null,
  icon: <FolderIcon className="asset-icon folder-icon" />,
  isOpen: true,
  path: '',
  title: null,
};

export default enhance(FolderListItem);
