import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  compose,
  withHandlers,
  setDisplayName,
  wrapDisplayName,
} from 'recompose';
import partial from 'lodash/partial';

import { toast } from 'components';
import { withApi } from 'infrastructure/api';
import parseValidationErrors from './parseValidationErrors';

const buildOptions = opts => ({
  getSave: props => {
    if (props.match.params.id) {
      return partial(props.api.update, props.match.params.id);
    }

    return props.api.create;
  },
  modifyData: id => id,
  onSuccess: (props, data, t, options) => {
    if (options.returnUrl) {
      props.history.push(options.returnUrl(data, props.menu));
    }
    t.success(options.successMessage);
  },
  ...opts,
});

const apiSaveHandler = ({ mapStateToProps, ...opts }) =>
  (options => WrappedComponent =>
    compose(
      setDisplayName(wrapDisplayName(WrappedComponent, 'apiSaveHandler')),
      withApi,
      connect(
        mapStateToProps,
        (dispatch, { api }) => ({
          api: bindActionCreators(options.getApi(api), dispatch),
        })
      ),
      connect(),
      withHandlers({
        onSubmit: props => (data, form) => {
          const save = options.getSave(props);

          save(
            options.modifyData(data),
            options.schema && {
              schema: options.schema,
            }
          ).then(({ payload, ...res }) => {
            if (!res.error) {
              if (options.entityName) {
                const { normalized } = payload;

                props.dispatch({
                  type: 'REPLACE_ENTITY',
                  payload: {
                    path: [options.entityName, normalized.result],
                    entity:
                      normalized.entities[options.entityName][
                        normalized.result
                      ],
                  },
                });
              }

              options.onSuccess(props, data, toast, options, res);
            } else {
              const {
                response: { error, message },
              } = payload;

              if (error.validationErrors) {
                const errors = parseValidationErrors(error.validationErrors);
                form.setErrors(errors);
              }

              if (message) {
                toast.error(message.split(/(?=[A-Z])/).join(' '));
              }

              form.setSubmitting(false);
            }
          });
        },
      })
    )(WrappedComponent))(buildOptions(opts));

export default apiSaveHandler;
