/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { TablePagination } from '@material-ui/core';

export default ({ metaData, options, ...props }) => (
  <TablePagination
    {...props}
    rowsPerPageOptions={
      options.rowsPerPageOptions && options.rowsPerPageOptions.length
        ? options.rowsPerPageOptions
        : [5, 10, 20]
    }
    page={metaData.page || 0}
  />
);
