import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { CheckboxField, DateTimeField, FormRow } from 'components/Form';
import './Scheduling.scss';
import PropTypes from 'prop-types';

class Scheduling extends Component {
  constructor(props) {
    super(props);
    this.toggleStartInput = this.toggleStartInput.bind(this);
    this.toggleEndInput = this.toggleEndInput.bind(this);
    this.state = { showStartInput: false, showEndInput: false };
  }

  toggleStartInput() {
    this.setState(state => ({
      showStartInput: !state.showStartInput,
    }));
    document.activeElement.blur();
  }

  toggleEndInput() {
    this.setState(state => ({
      showEndInput: !state.showEndInput,
    }));
    document.activeElement.blur();
  }

  render() {
    const { start, end } = this.props;
    const { showStartInput, showEndInput } = this.state;
    return (
      <div className="scheduling">
        {start && (
          <div className="action-group">
            <CheckboxField
              name="test"
              label="Publish on a Schedule"
              onChange={this.toggleStartInput}
            />
            <Collapse isOpen={showStartInput}>
              <FormRow label="Start Date and Time" borderless stacked>
                <DateTimeField
                  name="StartDateAndTime"
                  required
                  type="datetime"
                />
              </FormRow>
            </Collapse>
          </div>
        )}
        {end && (
          <div className="action-group">
            <CheckboxField
              name="test"
              label="Remove on a Schedule"
              onChange={this.toggleEndInput}
            />
            <Collapse isOpen={showEndInput}>
              <FormRow label="End Date and Time" borderless stacked>
                <DateTimeField name="EndDateAndTime" required type="datetime" />
              </FormRow>
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

Scheduling.propTypes = {
  start: PropTypes.bool,
  end: PropTypes.bool,
};

Scheduling.defaultProps = {
  start: true,
  end: true,
};

export default Scheduling;
