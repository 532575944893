import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import FileUploader from './FileUploader';

const Index = ({ match }) => (
  <Row>
    <Col>
      <FileUploader type={match.type} id={match.id} field="Script" />
    </Col>
  </Row>
);

Index.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

const enhance = compose(withRouter);

export default enhance(Index);
