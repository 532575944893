import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import renderChromeColorPickerField from 'shared/components/form/ChromeColorPicker';

const ColorPicker = props => {
  const { name, label, validate, onChange } = props;
  return (
    <Field
      {...props}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
      render={({ field, form, ...p }) =>
        renderChromeColorPickerField({
          input: {
            ...field,
            onChange: value => {
              form.setFieldValue(name, value.hex);
              onChange(value.hex);
            },
          },
          label,
          ...p,
          meta: {},
        })
      }
    />
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};
ColorPicker.defaultProps = {
  label: '',
  name: '',
  validate: [],
  onChange: () => {},
};

export default ColorPicker;
