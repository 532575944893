import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Breadcrumbs, Panel } from 'components';
import Title from '../Title';
import SubHeading from '../SubHeading';

const Content = ({ children, ...props }) => (
  <Container>
    <Row>
      <Col xs={12}>
        <Breadcrumbs {...props} />
        <Title {...props} />
        <SubHeading {...props} />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Panel className="main-content-card">{children}</Panel>
      </Col>
    </Row>
  </Container>
);

Content.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape()),
};

Content.defaultProps = {
  path: [{ url: '/', displayName: 'Home' }],
};

export default Content;
