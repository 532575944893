import React from 'react';
import PropTypes from 'prop-types';
import { Field, connect } from 'formik';
import { startCase, get } from 'lodash';
import { FormFeedback } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { processValidate } from 'infrastructure/form';
import './DateTimeField.scss';

const renderDatePickerField = props => <Flatpickr {...props} />;

const DateTimeField = props => {
  const { validate, label, mode, name, type, onChange, formik } = props;

  const placeholderOptions = {
    date: 'Date',
    time: 'Time',
    datetime: 'Date and Time',
  };

  const formatOptions = {
    date: 'Y/m/d',
    time: 'H:i',
    datetime: 'Y/m/d, H:i',
  };

  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = error && touched;

  return (
    <div className="form__form-group-input-wrap--error-below">
      <Field
        {...props}
        type="date"
        render={({ form, field, ...p }) =>
          renderDatePickerField({
            ...p,
            ...field,
            onChange: value => {
              const [date] = value;
              form.setFieldValue(name, date);
              form.setFieldTouched(name, true);
              if (onChange) {
                onChange(date);
              }
            },
            name,
            placeholder: placeholderOptions[type],
            options: {
              dateFormat: formatOptions[type],
              enableTime: type !== 'date',
              mode,
              noCalendar: type === 'time',
              time_24hr: true,
              timeFormat: formatOptions.time,
              utc: true,
            },
          })
        }
        validate={processValidate(label || startCase(name), validate, props)}
      />
      {showError && (
        <FormFeedback className="validation form__form-group-error" tag="small">
          {error}
        </FormFeedback>
      )}
    </div>
  );
};

DateTimeField.propTypes = {
  label: PropTypes.string,
  mode: PropTypes.oneOf(['single', 'multiple', 'range']),
  name: PropTypes.string,
  type: PropTypes.oneOf(['date', 'datetime', 'time']),
  validate: PropTypes.arrayOf(PropTypes.shape({})),
};

DateTimeField.defaultProps = {
  label: '',
  mode: 'single',
  name: '',
  type: 'datetime',
  validate: [],
};

export default connect(DateTimeField);
