import React from 'react';
import PropTypes from 'prop-types';
import { withConfig } from 'infrastructure/config';
import { Content } from 'components';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const Exports = ({ account, config }) => (
  <Content title="Exports">
    <Row>
      <Col>
        <a
          href={`${config.apiUrl}/api/exports/signups?accessToken=${
            account.access_token
          }`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
        >
          <span className="lnr lnr-download2" />
          {'   '}
          Export Email Signups
        </a>
      </Col>
    </Row>
  </Content>
);

Exports.propTypes = {
  account: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  account: state.account,
});

const enhance = compose(
  withConfig,
  connect(mapStateToProps)
);

export default enhance(Exports);
