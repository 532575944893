/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import classnames from 'classnames';
import { Button } from 'components';
/* eslint-enable no-unused-vars */

class MTableActions extends React.Component {
  renderButton(action, index) {
    if (typeof action === 'function') {
      action = action(this.props.data);
      if (!action) {
        return null;
      }
    }
    const ActionIcon = action.toolbarIcon || action.icon;

    const actionButton = (
      <Button
        key={action.icon + '' + index}
        disabled={action.disabled}
        className={classnames(action.toolbarClassName)}
        color={action.toolbarButtonPriority}
        onClick={event => {
          if (action.onClick) {
            action.onClick(event, this.props.data);
            event.stopPropagation();
          }
        }}
      >
        {action.title || action.tooltip}
        {typeof ActionIcon === 'string' ? (
          <Icon {...action.iconProps}>{action.ActionIcon}</Icon>
        ) : (
          <ActionIcon {...action.iconProps} />
        )}
      </Button>
    );
    return actionButton;
  }

  render() {
    if (this.props.actions) {
      return this.props.actions.map((action, index) =>
        this.renderButton(action, index)
      );
    }

    return null;
  }
}

MTableActions.defaultProps = {
  actions: [],
  data: {},
};

MTableActions.propTypes = {
  actions: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default MTableActions;
/* eslint-enable prefer-template */
/* eslint-enable react/destructuring-assignment */
/* eslint-enable react/default-props-match-prop-types */
/* eslint-enable react/forbid-prop-types */
/* eslint-enable react/require-default-props */
/* eslint-enable no-param-reassign */
